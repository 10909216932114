<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Update Payroll Formula</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active">
                   <router-link :to="{name: 'payroll-formulas'}">
                        <span>Payroll Formulas</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Edit</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <form v-on:submit.prevent="updatePayrollFormula()">
                        <div class="form-group row">
                            <label class="col-md-3">
                                Name
                                <span class="text-danger" style="font-weight:bold;" v-if="slug_status != payroll_formula.slug">*</span>
                            </label>

                            <div class="col-md-8">
                                <input v-if="slug_status != payroll_formula.slug"
                                    v-model="payroll_formula.name"
                                    @change="textTitleCase($event, 'name')"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="reasonHelpInline"
                                    autocomplete="off"
                                    v-on:blur="nameToSlug"
                                />
                                <span v-else>
                                    {{payroll_formula.name}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3">
                                Slug
                            </label>

                            <div class="col-md-8">
                                <input
                                    v-model="payroll_formula.slug"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="reasonHelpInline"
                                    autocomplete="off"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="form-group row" v-if="payroll_formula.conditions[payroll_formula.conditions.length-1]">
                            <label class="col-md-3"> 
                                <span v-if="payroll_formula.conditions.length>1">Default</span> Formula
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-8">
                                <input
                                    v-model="payroll_formula.conditions[payroll_formula.conditions.length-1]['formula']"
                                    type="text"
                                    class="form-control"
                                    aria-describedby="reasonHelpInline"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                        <div v-for="(condition, index) in payroll_formula.conditions.slice(0,payroll_formula.conditions.length-1)" :key="index">
                            <div class="form-group row" v-if="condition.hasOwnProperty('condition')">
                                <label class="col-md-3"> 
                                    Condition{{ index+1 }}
                                    <span class="text-danger" style="font-weight:bold;">*</span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        v-model="condition.condition"
                                        type="text"
                                        class="form-control"
                                        aria-describedby="reasonHelpInline"
                                        autocomplete="off"
                                    />
                                </div>
                                <div class="col-md-1">
                                    <span v-b-tooltip.hover title="Remove Condition" @click="removeCondition(index)" style="cursor:pointer;" class="text-danger">
                                        <font-awesome-icon icon="trash-alt" />
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3"> 
                                    Formula{{ index+1 }}
                                    <span class="text-danger" style="font-weight:bold;">*</span>
                                </label>
                                <div class="col-md-8">
                                    <input
                                        v-model="condition.formula"
                                        type="text"
                                        class="form-control"
                                        aria-describedby="reasonHelpInline"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <div class="offset-md-3 col-md-8">
                                <a href="javascript:void(0);" class="float-right" @click="addCondition">Add Condition</a>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3">
                                Applicable For
                            </label>

                            <div class="col-md-8">
                            <b-form-group>
                                <b-form-checkbox
                                    v-for="(options, index) of earning_deduction_field_options" :key="index"
                                    :value="options.value"
                                    v-model="payroll_formula.applicable_for"
                                >
                                    {{options.text}}
                                </b-form-checkbox>
                            </b-form-group>

                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-11">
                                <div class="form-group row" style="float:right;">
                                    <div class="offset-xs-3 col-xs-9">
                                        <button
                                            type="button"
                                            class="btn btn-primary text-right ma-10px"
                                            v-on:click="updatePayrollFormula()"
                                        >Submit</button>
                                    </div>
                                    <div class="offset-xs-3 col-xs-9">
                                        <button
                                            type="button"
                                            @click="$router.go(-1)"
                                            class="btn btn-secondary ma-10px"
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="col-md-4">
                    <b-card-group deck>
                        <b-card
                            border-variant="black"
                            header="Available Variables"
                            header-bg-variant="default"
                            header-text-variant="black"
                            align="left"
                        >
                            <b-card-text v-for="(variable, index) in default_formula_variables" :key="index" > [{{variable}}] </b-card-text>
                        </b-card>
                    </b-card-group>
                    <br> <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound,
    },
    data() {
        return {
            payroll_formula:{
                name: "",
                slug: "",
                conditions:[],
                applicable_for: [],

            },
            slug_status: "",
            default_formula_variables: '',
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            earning_deduction_field_options:[
                {text: "Default Earnings", value:"default-earnings"},
                {text: "Default Deductions", value: "default-deductions"},
                {text: "Slab Earnings", value: "slab-earnings"},
                {text: "Slab Deductions", value: "slab-deductions"},
                {text: "Group Earnings", value: "group-earnings"},
                {text: "Group Deductions", value: "group-deductions"},
                {text: "Employe Earnings", value: "employee-earnings"},
                {text: "Employee Deductions", value: "employee-deductions"},
            ]
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL_FORMULAS");
        this.default_formula_variables = this.$store.getters.getAppSetting("payroll", "pre_calculated_variables");
        this.getParollFormula();
        this.getPayrollFormulas();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.payroll_formula[key] = this.strTitleCase(event.target.value);
        },
        
        getParollFormula() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.id
            };
            this.axios
                .get(this.$api.get_payroll_formulas, query)
                .then(response => {
                    let result = response.data.data;
                    this.payroll_formula.name=result.name;
                    this.payroll_formula.slug=result.slug;
                    this.payroll_formula.conditions=JSON.parse(result.conditions);
                    this.payroll_formula.applicable_for=JSON.parse(result.applicable_for);
                    this.slug_status = this.default_formula_variables.filter( item => (item == this.payroll_formula.slug) );
                    this.api_error = "";
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        updatePayrollFormula() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            query["params"] = {};
            let params = {
                id: this.$route.params.id,
                conditions: this.payroll_formula.conditions,
                applicable_for: this.payroll_formula.applicable_for,

            };
            if(this.slug_status != this.payroll_formula.slug){
                params['name'] = this.payroll_formula.name;
                params['slug'] = this.payroll_formula.slug;
            }
            params = this.deleteEmptyKeys(params);
            this.axios
                .post(this.$api.update_payroll_formulas, params, query)
           .then(() => {
                this.updateProgressBar(true);
                this.api_error = "";
                this.$router.push({name: 'payroll-formulas'});
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        nameToSlug: function() {
            this.payroll_formula.slug =  this.payroll_formula.name.trim().replace(/ /g,'_').toLocaleLowerCase();
        },

        addCondition: function() {
            for (let i = 0; i<this.payroll_formula.conditions.length; i++) {
                if ((('condition' in this.payroll_formula.conditions[i]) && !this.payroll_formula.conditions[i]['condition'].trim()) || !this.payroll_formula.conditions[i]['formula'].trim()) {
                    this.api_error = 'Please fill all the fields before adding a new condition.';
                    this.updateProgressBar(false);
                    this.showAlert();
                    return;
                }
            }
            this.payroll_formula.conditions.splice(this.payroll_formula.conditions.length-1,0 ,{ condition: '', formula: '' });
        },

        removeCondition: function(index) {
            this.payroll_formula.conditions = this.payroll_formula.conditions.filter((condition, i) => i!=index);
        },

        getPayrollFormulas() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_payroll_formulas, query).then(response => {
                response.data.data.forEach(payroll_formulas => {
                    this.default_formula_variables.push(
                        payroll_formulas.slug
                    );
                });
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>
